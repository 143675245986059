import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  shipNameText: {
    padding: 15,
    color: "#aaa"
  },
  shipProbabilityText: {
    padding: 10,
    color: "#aaa"
  },
  bodyText: {
    fontFamily: "OpenSans Regular",
    padding: 12,
    lineHeight: 18,
    color: "#ddd"
  },
  rulesText: {
    fontFamily: "OpenSans Regular",
    padding: 10,
    color: "#aaa"
  }
});
