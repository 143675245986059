import React, { useEffect, useState } from "react";
import { Image, StyleSheet, Text, View } from "react-native";

import { SceneView } from "@react-navigation/core";

import { Link } from "@react-navigation/web";
import { useNavigationEvents } from "react-navigation-hooks";
import { API, graphqlOperation } from "aws-amplify";

import * as queries from "../queries";
import Countdown from "./countdown";

export default function Nav({ descriptors, navigation }) {
  const [events, setEvents] = useState([]);
  useNavigationEvents(evt => {
    setEvents([...events, evt.type]);
  });
  const activeKey = navigation.state.routes[navigation.state.index].key;
  const descriptor = descriptors[activeKey];

  const [activeShips, setActiveShips] = useState([]);
  const [launchDate, setLaunchDate] = useState(null);

  useEffect(() => {
    const listShipsQuery = async () => {
      const { data } = await API.graphql(graphqlOperation(queries.listShips));
      if (data) {
        const {
          launchDate,
          listShips: { items: shipList }
        } = data;
        setLaunchDate(launchDate);
        setActiveShips(shipList);
      }
    };
    listShipsQuery();
  }, []);
  return (
    <View style={styles.container}>
      <View style={styles.navContainer}>
        {Object.keys(descriptors).map(d => (
          <View key={d} style={{ padding: 15 }}>
            <Link
              routeName={descriptors[d].navigation.state.routeName}
              navigation={navigation}
            >
              <Text style={styles.linkText}>
                {descriptors[d].options.linkName ||
                  descriptors[d].navigation.state.routeName}
              </Text>
            </Link>
          </View>
        ))}
        <View style={{ padding: 15, alignSelf: "flex-end" }}>
          <a
            href="https://discord.gg/Y8SSC65"
            target="_blank"
            rel="noopener noreferrer"
            style={{ width: 105 }}
          >
            <Image
              resizeMode="contain"
              style={{ height: 32, width: 105 }}
              source={require("./discord.svg")}
            />
          </a>
        </View>
      </View>
      <Countdown launchDate={launchDate} />
      <SceneView
        component={descriptor.getComponent()}
        navigation={descriptor.navigation}
        screenProps={{ activeShips }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: { width: "100%", flex: 1, backgroundColor: "#222", padding: 10 },
  linkText: {
    color: "white",
    fontWeight: "bold",
    fontFamily: "OpenSans Regular"
  },
  linkNav: { padding: 15 },
  navContainer: {
    height: 100,
    width: "100%",
    paddingVertical: 15,
    alignItems: "center",
    flexDirection: "row"
  }
});
