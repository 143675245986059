import React from "react";
import {
  Animated,
  Image,
  StyleSheet,
  Text,
  TouchableOpacity,
  ScrollView,
  View
} from "react-native";

import {
  createNavigator,
  getActiveChildNavigationOptions,
  SwitchRouter,
  SceneView
} from "@react-navigation/core";
import { createBrowserApp, Link } from "@react-navigation/web";
import { API, graphqlOperation } from "aws-amplify";

import Nav from "./nav";
import Home from "./home";
import Rankings from "./current-rankings";
import Install from "./install";
import Rules from "./rules";

const AppNavigator = createNavigator(
  Nav,
  SwitchRouter({
    Home,
    Rankings: ({ screenProps }) => {
      return <Rankings {...screenProps} />;
    },
    //Install,
    Rules
  }),
  {
    navigationOptions: ({ navigation, screenProps }) => {
      const options = getActiveChildNavigationOptions(navigation, screenProps);
      return { title: options.title };
    }
  }
);

const App = createBrowserApp(AppNavigator);

export default App;
