import React from "react";
import { StyleSheet, Text, ScrollView, View } from "react-native";
import { styles } from "./ui";

export default function Home() {
  return (
    <View style={{ flex: 1 }}>
      <Text style={[styles.rulesText, { fontFamily: "Pirata", fontSize: 24 }]}>
        Welcome to Rising Tide!
      </Text>
      <Text style={styles.bodyText}>
        Rising Tide is an extension that drives viewer engagement via collection
        of resources. Every week, streamers have a chance to win prizes such as
        subs, gift cards, etc., found on Treasure Island. The more wood your
        audience collects, the higher your chances of safely reaching Treasure
        Island. The more rum your audience collects, the higher your chances of
        striking gold on the island - watch out though; rum weighs down your
        ship, so you'll need to make sure you collect enough wood to build a
        ship sturdy enough to survive the voyage.
      </Text>
      <Text style={styles.bodyText}>
        Each stream has a week to finish building their ship before a universal
        launch each week. Shortly after launch, all ships that have successfully
        sailed to Treasure Island are entered into a drawing for prizes. Again,
        the more rum your crew collected in the week leading up to the drawing,
        the better your odds are of winning.
      </Text>
      <Text
        style={[
          styles.bodyText,
          { color: "white", fontWeight: "bold", fontSize: 18 }
        ]}
      >
        Head on over to{" "}
        <a
          href="https://www.twitch.tv/ext/nwbwiwa1w0qcrm1lwa1ydpft0um2sz"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Text style={{ color: "slateblue" }}>Twitch</Text>
        </a>{" "}
        to install Rising Tide!
      </Text>
      <Text style={styles.bodyText}>Best of luck!</Text>
    </View>
  );
}

Home.path = "";
Home.navigationOptions = {
  title: "Rising Tide",
  linkName: "Rising Tide"
};
