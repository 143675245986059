import React from "react";
import { Text, View } from "react-native";
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryPie
} from "victory";
import { styles } from "./ui";

const Rankings = function({ activeShips }) {
  if (activeShips.length < 1) {
    return null;
  }

  const coolShips = [...activeShips, { name: "adventUr_goat", rumAmount: 50 }];

  const totalRumAmount = coolShips
    .filter(({ rumAmount }) => rumAmount > 0)
    .reduce((curTotal, { rumAmount }) => curTotal + rumAmount, 0);

  const dataForPie = coolShips
    .filter(({ rumAmount }) => rumAmount > 0)
    .map(({ name, rumAmount }) => ({
      x: `${name} (${((rumAmount / totalRumAmount) * 100).toFixed(1)}%)`,
      y: rumAmount
    }));

  return (
    <>
      <Text style={[styles.rulesText, { fontFamily: "Pirata", fontSize: 24 }]}>
        Rankings
      </Text>

      <View>
        <View style={{ width: "60%" }}>
          <View
            style={{
              height: 500,
              width: "100%"
            }}
          >
            <Text
              style={[styles.rulesText, { fontFamily: "Pirata", fontSize: 20 }]}
            >
              Reaching Treasure Island Odds
            </Text>
            <Text style={styles.bodyText}>
              Here you'll find each ship's % chance of successfully reaching
              Treasure Island. Collect more wood to increase your ship's
              chances!
            </Text>
            <VictoryChart
              animate={{
                duration: 2000,
                onLoad: { duration: 1000 }
              }}
              domainPadding={{ x: 30 }}
            >
              <VictoryBar
                data={[
                  ...activeShips,
                  { name: "adventUr_goat", reachDestinationProbability: 50.1 }
                ]}
                x="name"
                y="reachDestinationProbability"
                height={460}
                labels={d =>
                  `${d.name} (${d.reachDestinationProbability.toFixed(0)}%)`
                }
                //labelComponent={<VictoryLabel x={80} />}
                horizontal
                style={{
                  labels: { fill: "white" },
                  data: { fill: "rgb(82, 82, 82)" }
                }}
              />
              <VictoryAxis
                dependentAxis
                tickValues={[25, 50, 75, 100]}
                maxDomain={{ y: 100 }}
                style={{
                  axis: { stroke: "#756f6a" },
                  axisLabel: { fontSize: 20, padding: 30, fill: "white" },

                  labels: { fill: "white" },
                  tickLabels: { fontSize: 15, padding: 5, fill: "white" }
                }}
              />
            </VictoryChart>
          </View>
          <Text
            style={[styles.rulesText, { fontFamily: "Pirata", fontSize: 20 }]}
          >
            Grand Prize Treasure Island Odds
          </Text>
          <Text style={styles.bodyText}>
            Here you'll find each ship's % chance of winning the weekly prize
            upon successfully reaching Treasure Island (based on your gathered
            rum relative to other channels' gathered rum). Collect more rum to
            increase your ship's chances!
          </Text>
          <VictoryChart>
            <VictoryPie
              data={dataForPie}
              labelRadius={90}
              style={{
                data: {
                  fillOpacity: 0.9,
                  stroke: "#aaa",
                  strokeWidth: 2
                },
                labels: { fill: "white", fontSize: 8, fontWeight: "bold" }
              }}
            />
          </VictoryChart>
        </View>
      </View>
    </>
  );
};

Rankings.path = "rankings";

Rankings.navigationOptions = {
  title: "Rising Tide - Rankings",
  linkName: "Rankings"
};

export default Rankings;
