import React from "react";
import { StyleSheet, Text, ScrollView, View } from "react-native";
import { styles } from "./ui";

export default function Install() {
  return (
    <View style={{ flex: 1 }}>
      <Text style={[styles.rulesText, { fontFamily: "Pirata", fontSize: 20 }]}>
        Install
      </Text>
    </View>
  );
}

Install.path = "install";
Install.navigationOptions = {
  title: "Rising Tide - Install",
  linkName: "Rising Tide - Install"
};
