import React, { useEffect, useRef, useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import useInterval from "use-interval";

import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds
} from "date-fns";

export default function CountDown({ launchDate }) {
  const nextPhase = new Date(launchDate);
  const [countdownDisplay, setCountdownDisplay] = useState("");

  useInterval(
    () => {
      const now = new Date();

      if (now > nextPhase) {
        setCountdownDisplay(null);
        return;
      }

      const padZero = number => number.toString().padStart(2, "0");
      const days = padZero(differenceInDays(nextPhase, now));
      const hours = padZero(differenceInHours(nextPhase, now) % 24);
      const minutes = padZero(differenceInMinutes(nextPhase, now) % 60);
      const seconds = padZero(differenceInSeconds(nextPhase, now) % 60);

      setCountdownDisplay(`${days}:${hours}:${minutes}:${seconds}`);
    },
    1000,
    true
  );

  return (
    <View
      style={{
        justifyContent: "center",
        padding: 10
      }}
    >
      <Text
        style={{
          fontSize: 18,
          color: "white",
          fontFamily: "OpenSans Regular",
          fontWeight: "bold"
        }}
      >
        {countdownDisplay && `All ships launch in: ${countdownDisplay}!`}
      </Text>
    </View>
  );
}
