import React from "react";
import { StyleSheet, Text, ScrollView, View } from "react-native";
import { styles } from "./ui";

export default function Rules() {
  return (
    <>
      <Text style={[styles.rulesText, { fontFamily: "Pirata", fontSize: 24 }]}>
        Rules
      </Text>
      <ScrollView>
        <Text style={styles.rulesText}>
          NO PURCHASE IS NECESSARY TO ENTER OR WIN. A PURCHASE DOES NOT INCREASE
          THE CHANCES OF WINNING.
        </Text>
        <Text style={styles.rulesText}>
          THIS IS A PROMOTION BY TAUNT INC. TWITCH DOES NOT SPONSOR OR ENDORSE
          TAUNT INC.'S PROMOTION AND IS NOT RESPONSIBLE FOR THIS PROMOTION.
        </Text>
        <Text style={styles.rulesText}>
          1. Eligibility: Sweepstakes (the "Sweepstakes") is open only to those
          who sign up at the online sweepstakes page and who are 18 or older as
          of the date of entry. The sweepstakes is only open to legal residents
          of the United States and is void where prohibited by law. Employees of
          Taunt Inc. (the "Sponsor") their respective affiliates, subsidiaries,
          advertising and promotion agencies, suppliers and their immediate
          family members and/or those living in the same household of each are
          not eligible to participate in the Sweepstakes. The Sweepstakes is
          subject to all applicable federal, state and local laws and
          regulations. Void where prohibited.
        </Text>
        <Text style={styles.rulesText}>
          2. Agreement to Rules: By participating, you agree to be fully
          unconditionally bound bythese Rules, and you represent and warrant
          that you meet the eligibility requirements set forth herein. In
          addition, you agree to accept the decisions of Taunt Inc., as final
          and binding as it relates to the content. The Sweepstakes is subject
          to all applicable federal, state and local laws.
        </Text>
        <Text style={styles.rulesText}>
          3. Sweepstakes Period: Entries will be accepted online on an ongoing
          basis starting on or about July 1, 2019 and ending at that period’s
          prize drawing. All online entries must be received by that week’s
          respective prize drawing. Ongoing prize periods may change at Taunt’s
          discretion.
        </Text>
        <Text style={styles.rulesText}>
          4. How to Enter: The Sweepstakes must be entered by using the Rising
          Tide Twitch extension on your Twitch channel during livestreams. There
          are two separate drawings to determine a winner. A channel's Boat
          Strength, as calculated by how much wood your channel has collected in
          relation to rum, determines whether or not they are considered in the
          final prize drawing. In the final prize drawing, each rum collected
          for a streamer counts as one Sweepstakes entry. Entries that are not
          complete or do not adhere to the rules or specifications may be
          disqualified at the sole discretion of Taunt, Inc.. You may enter
          multiple times, and additional entries will increase your odds of
          winning. You may not enter more times than indicated by using multiple
          Twitch accounts, identities or devices in an attempt to circumvent the
          rules. If you use fraudulent methods or otherwise attempt to
          circumvent the rules your submission may be removed from eligibility
          at the sole discretion of Taunt, Inc.
        </Text>
        <Text style={styles.rulesText}>
          5. Prizes: Winners will receive a $250 Amazon.com Gift Card, worth
          $250 US dollars.The specifics of the prize shall be solely determined
          by the Sponsor. No cash or other prize substitution permitted except
          at Sponsor's discretion. The prize is non-transferable. Any and all
          prize related expenses, including without limitation any and all
          federal, state, and/or local taxes shall be the sole responsibility of
          the winner. No substitution of prize or transfer/assignment of prize
          to others or request for the cash equivalent by winners is permitted.
          Acceptance of prize constitutes permission for Taunt,Inc. to use
          winner's name, likeness, and entry for purposes of advertising and
          trade without further compensation, unless prohibited by law.
        </Text>
        <Text style={styles.rulesText}>
          6. Odds: The odds of winning depend on the number of eligible entries
          received.
        </Text>
        <Text style={styles.rulesText}>
          7. Winner selection and notification: Winners of the Sweepstakes will
          be selected in a random drawing under the supervision of the Sponsor.
          Winners will be notified via a Twitch livestream on the PlayTaunt
          channel, with the time and date being advertised within the Rising
          Tide extension for each respective drawing. Winners must reply to
          Taunt via Twitch message within five (5) days following the winner
          selection. Taunt, Inc. shall have no liability for a winner's failure
          to receive notices due to missing Taunt livestream, not viewing
          winners list on playtaunt.com, or other failures. If the selected
          winner cannot be contacted, is ineligible, fails to claim the prize
          within 15 days from the time award notification was sent, or fails to
          timely return a completed and executed declaration and releases as
          required, prize may be forfeited and an alternate winner selected
        </Text>
        <Text style={styles.rulesText}>
          The receipt by winner of the prize offered in this Sweepstakes is
          conditioned upon compliance with any and all federal and state laws
          and regulations. ANY VIOLATION OF THESE OFFICIAL RULES BY ANY WINNER
          (AT SPONSOR'S SOLE DISCRETION) WILL RESULT IN SUCH WINNER'S
          DISQUALIFICATION AS WINNER OF THE SWEEPSTAKES AND ALL PRIVILEGES AS
          WINNER WILL BE IMMEDIATELY TERMINATED.
        </Text>
        <Text style={styles.rulesText}>
          8. Rights Granted by you: By entering this content you understand that
          Taunt, Inc., anyone acting on behalf of Taunt, Inc., or its respective
          licensees, successors and assigns will have the right, where permitted
          by law, without any further notice, review or consent to print,
          publish, broadcast, distribute, and use, worldwide in any media now
          known or hereafter in perpetuity and throughout the World, your entry,
          including, withoutlimitation, the entry and winner's name, portrait,
          picture, voice, likeness, image or statements about the Sweepstakes,
          and biographical information as news, publicity or information and for
          trade, advertising, public relations and promotional purposes
          withoutany further compensation.
        </Text>
        <Text style={styles.rulesText}>
          9. Terms: Taunt, Inc.reserves the right, in its sole discretion to
          cancel, terminate, modify or suspend the Sweepstakes should (in its
          sole discretion) a virus, bugs, non-authorized human intervention,
          fraud or other causes beyond its control corrupt or affectthe
          administration, security, fairness or proper conduct of the
          Sweepstakes. In such case, Taunt, Inc. may select the recipients from
          all eligible entries received prior to and/or after (if appropriate)
          the action taken by Taunt, Inc. Taunt, Int.reserves the right at its
          sole discretion to disqualify any individual who tampers or attempts
          to tamper with the entry process or the operation of the Sweepstakes
          or website or violates these Terms & Conditions.
        </Text>
        <Text style={styles.rulesText}>
          Taunt, Inc. has the right, in its sole discretion, to maintain the
          integrity of the Sweepstakes, to void votes for any reason, including,
          but not limited to; multiple entries from the same user from different
          IP addresses; multiple entries from the same computer in excess of
          that allowed by sweepstakes rules; or the use of bots, macros or
          scripts or other technical means for entering.
        </Text>
        <Text style={styles.rulesText}>
          Any attempt by an entrant to deliberately damage any web site or
          undermine the legitimate operation of the sweepstakes may be a
          violation of criminal and civil laws and should such an attempt be
          made, Taunt, Inc.reserves the right to seek damages from any such
          person to the fullest extent permitted by law.
        </Text>
        <Text style={styles.rulesText}>
          10. Limitation of Liability: By entering you agree to release and hold
          harmless Taunt, Inc.and its subsidiaries, affiliates, advertising and
          promotion agencies, partners, representatives, agents, successors,
          assigns, employees, officers and directors from any liability,
          illness, injury, death, loss, litigation, claim or damage that may
          occur, directly or indirectly, whether caused by negligence or not,
          from (i) such entrant's participation in the sweepstakes and/or
          his/her acceptance, possession, use, or misuse of any prize or any
          portion thereof, (ii) technical failures of any kind, including but
          not limited to the malfunctioning of any computer, cable, network,
          hardware or software; (iii) the unavailability or inaccessibility of
          any transmissions or telephone or Internet service;(iv) unauthorized
          human intervention in any part of the entry process or the Promotion;
          (v) electronic or human error which may occur in the administration of
          the Promotion or the processing of entries.
        </Text>
        <Text style={styles.rulesText}>
          11. Disputes: THIS SWEEPSTAKES IS GOVERNED BY THE LAWS OF THE UNITED
          STATES AND DELAWARE, WITHOUT RESPECT TO CONFLICT OF LAW DOCTRINES. As
          a condition of participating in this Sweepstakes, participant agrees
          that any and all disputes which cannot be resolved between the
          parties, and causes of action arising out of or connected with this
          Sweepstakes, shall be resolved individually, without resort to any
          form of class action, exclusively before a court located in
          Delawarehaving jurisdiction. Further, in any such dispute, under no
          circumstances will participant be permitted to obtain awards for, and
          hereby waives all rights to claim punitive, incidental, or
          consequential damages, including reasonable attorneys' fees, other
          than participant's actual out-of-pocket expenses (i.e. costs
          associated with entering this Sweepstakes), and participant further
          waives all rights to have damages multiplied or increased.
        </Text>
        <Text style={styles.rulesText}>
          12. Privacy Policy: Information submitted with an entry is subject to
          the Privacy Policy stated on the Taunt, Inc.Web Site. The Privacy
          Policy is available at http://playtaunt.com/privacy.
        </Text>
        <Text style={styles.rulesText}>
          13. Winners List: To view the winner's name or a copy of these
          Official Rules, visit http://playrisingtide.com/rules.
        </Text>
        <Text style={styles.rulesText}>
          14. Sponsor: The Sponsor of the Sweepstakes is Taunt, Inc. via
          Corporation Trust Center, 1209 Orange Street, in the City of
          Wilmington, County of New Castle, Delaware 19801.
        </Text>
      </ScrollView>
    </>
  );
}

Rules.path = "rules";
Rules.navigationOptions = {
  title: "Rising Tide - Rules",
  linkName: "Rules"
};
